<template>


            <div class="con-wrap">
                <CarrotTitle title="세금계산서 미발행 관리(ITM)">
                    -세금계산서 미발행된 ITM 클래스 목록입니다.
                </CarrotTitle>
                <div class="board">
                    <div class="mt-40">
                        <div>

                            <table class="table-row table-serach">
                                <tbody>
                                    <tr>
                                        <th width="180">기간</th>
                                        <td>
                                            <CarrotDatePicker v-model="search.sdate"></CarrotDatePicker> ~ <CarrotDatePicker v-model="search.edate"></CarrotDatePicker>

                                            <!-- <select v-model="search.year" class="w-80px" @change="comp.find">
                                                <option v-for="year in comp.years" :key="year" :value="year">{{year}}</option>
                                            </select>
                                            <select v-model="search.month" class="w-70px ml-10" @change="comp.find">
                                                <option :value="m" v-for="m in comp.months" :key="m" >{{m}}월</option>
                                            </select> -->
                                        </td>
                                        <th width="180">법인</th>
                                        <td>
                                            <select v-model="search.corp" class="w-70per">
                                                <option value="">선택</option>
                                                <option value="C">캐럿솔루션즈</option>
                                                <option value="G">캐럿글로벌</option>
                                                <option value="E">캐럿에듀</option>
                                            </select>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <button class="btn-default float-right mt-10" @click="comp.getList()">검색</button>
                            <div class="clear"></div>

                            <div class="mt-50 mb-20 dp-table w-100per">
                                <button class="btn-default float-right" @click="comp.getExcel()">엑셀다운로드</button>
                            </div>
                            
                            <table class="table-col">
                                <colgroup>
                                    <col width="80">
                                    <col width="150">
                                    <col width="150">
                                    <col width="180">
                                    <col width="280">
                                    <col width="120">
                                    <col width="120">
                                    <col width="90">
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th>No</th>
                                        <th>매니저</th>
                                        <th>팀</th>
                                        <th>회사명</th>
                                        <th>Class명</th>
                                        <th>시작일</th>
                                        <th>종료일</th>
                                        <th>ITM</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-if="comp.list.length==0">
                                        <td colspan="8">데이터가 없습니다.</td>
                                    </tr>
                                    <tr v-for="(itm, idx) in comp.list" :key="idx">
                                        <td>{{itm.num}}</td>
                                        <td>{{itm.englishname}}({{itm.koreanname}})</td>
                                        <td>{{itm.deptname}}</td>
                                        <td class="txt-ellipsis">{{itm.companyname}}</td>
                                        <td class="txt-ellipsis">{{itm.classname}}</td>
                                        <td>{{itm.sdate}}</td>
                                        <td>{{itm.edate}}</td>
                                        <td>
                                            <a :href="'https://hq.carrotenglish.net/class/classview?idx=' + itm.regid + '&classno=' + itm.classno" target="new"><span class="btn-view">View</span></a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <CarrotPaging v-if="search.total>0" :total="search.total" :list_per_page="50" v-model="search.page" @change="comp.getList()"></CarrotPaging>

                        </div>
                    </div>
                </div>
            </div>

</template>


<script>
// @ is an alias to /src
import { onMounted, reactive } from 'vue';
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js';
import CarrotDatePicker from '@/components/common/CarrotDatePicker.vue';
// import { useStore } from 'vuex'
// import { useRouter } from 'vue-router'


export default {
    layout:"documents",
    components: {
        CarrotDatePicker
    },
    setup() {
        const toast = useToast()
        // const store = useStore();
        // const router = useRouter();
        const search = reactive({
            page: 1,
            sdate : "",
            edate : "",
            // year : "",
            // month : "",
            corp : "",
            total : 0
        });

        const comp = reactive({
            years : [],
            months : [],
            list : [],

            getExcel: () => {
                let params = {
                    page   : search.page,
                    // year   : search.year,
                    // month : search.month,
                    sdate : search.sdate,
                    edate : search.edate,
                    pay_gubun : search.corp
                };

                let str = Object.entries(params).map(([key, val]) => `${key}=${val}`).join('&');
                // ?page=1&year=2021&month=10&companyname=%EC%97%90%EC%8A%A4%EC%BC%80%EC%9D%B4&pay_gubun=&part=&team=&mem_seq=
                location.href="https://api.carrotians.net/excel/itm_taxtbill_list?" + str;
            },

            getList : () => {
                let params = {
                    page   : search.page,
                    // year   : search.year,
                    // month : search.month,
                    sdate : search.sdate,
                    edate : search.edate,
                    pay_gubun : search.corp
                };

                let str = Object.entries(params).map(([key, val]) => `${key}=${val}`).join('&');
                // ?page=1&year=2021&month=10&companyname=%EC%97%90%EC%8A%A4%EC%BC%80%EC%9D%B4&pay_gubun=&part=&team=&mem_seq=

                axios.get("/rest/sales/getITMTaxbillList?"+str, { params : params }).then((res) => {
                    comp.list     = res.data.list;
                    search.total    = res.data.total;
                    if( res.data.err > 0 ){
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            number_format: (num) => { 
                let price = num.toString().replace(/,/g, "");
                price = parseFloat(price);
                if(isNaN(price)) {
                    price = 0;
                }
                return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            },

            
            dateToString(ndate) {
                return [ndate.getFullYear(),((ndate.getMonth() + 1)>9 ? '' : '0') + (ndate.getMonth() + 1),(ndate.getDate()>9 ? '' : '0') + ndate.getDate()].join('-');
            },

            makeSearchYears: () => {
                let cd = new Date();
                let cy = cd.getFullYear();
                for(let i=cy; i>=2020; i--){
                    comp.years.push(i);
                }
                for(let i=1; i<=12; i++){
                    comp.months.push(i);
                }
                
            }
        });

        onMounted(() => {
            // Mounted
            // if(store.state.position!='리드' && store.state.isSalesAdmin ) {
            //     router.push({ 
            //         path:"/main"
            //     });
            //     return;
            // }

            let today = new Date();
            let sdate = new Date(today.getFullYear(), today.getMonth(), 1);
            let edate = new Date(today.getFullYear(), today.getMonth()+1, 0);
            search.sdate = comp.dateToString(sdate);
            search.edate = comp.dateToString(edate);

            comp.makeSearchYears();

            let ss = sessionStorage.getItem("statusCarrotTotalSalesList");
            if( ss ){
                ss = JSON.parse(ss);
                comp.company = ss.company?ss.company:0;
                console.log(ss);
            }

            comp.getList();
        });

        return {comp, search};
    }
}
</script>

<style lang="scss" scoped>
</style>